import React from "react"
import PrivateRoute from "../../components/privateRoute";
import Referral from "../../components/referral";

class Page extends React.Component {
    render() {
        return (
            <PrivateRoute path="/account/referral" component={Referral} location={this.props.location} showFooter={false} greyBackground={true} seoFields={seoFields}/>
        )
    }
}

const seoFields = {
    description: "",
    // lang,
    // meta,
    // keywords,
    title: "Referral Panel" 
}

export default Page