import React from "react"
import { navigate, Link } from "gatsby"
import styles from "./styles.module.css";
import PlanSideBar from "../../shared/planSideBar";
import {  getToken, getUser } from "../../services/auth";
import FormField from "../../shared/formField";
import { shareReferralCode, viewCustomerReferralDetails } from "../../services/customerInfo";
import { emailInvalid, requiredError } from "../../common/validation";
import ProfileSectionHeading from '../../shared/profileSectionHeading';
import ProceedButton from "../proceedButton";
var dateFormat = require('dateformat');

class Referral extends React.Component {
  constructor(props) {
      super(props);
      const user = getUser();
      const isTemp = user && user.id==='TEMP';

      this.state = {
        identityDisabled: true,
        ReferralDetails: {
          ReferralCode: "",
          ReferralLink : "",
          ReferralPoints: 0,
          ReferralHistory: [],
        },
        ReferralInvite: {
          EmailAddr: '',
          dirty: false
        },
        inviteLoading: false,
        dirty: false,
        inviteText: 'Invite',
        isTemp
      }
  }

  componentDidMount() {
    if (this.state.isTemp) {
      navigate('/account/plan');
    } else {
      this.fetchCustomerDetails(getToken());
    }
  }

  copyText = () => {
    // this.refs.input.select();
    document.execCommand('copy');
    return false;
  }

  onChange = (e, type, isNumber=false) => {
    const value = isNumber ? +e.target.value : (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
      const section = type ? this.state[type] : this.state;
      section[e.target.name] = value;
      var BillingDetails = this.state.BillingDetails;
      if (this.state.IsBillingSame && type === 'CustomerDetails') {
        BillingDetails[e.target.name] = value;
      }
      if (e.target.name === 'IsBillingSame') {
        this.setState({[e.target.name]: value, BillingDetails: value ? {...this.state.CustomerDetails} : this.state.BillingDetails});
      } else {
        this.setState({[section]: section, BillingDetails});
      }
  }

  fetchCustomerDetails = (token) => {
    viewCustomerReferralDetails(token)
    .then(({error,response}) =>{
      if (error.status<400) {
        this.setState({ 
          ReferralDetails: response,
        }) 
      }
    }).catch(error => {
      console.log("Error:", error);
    });
  }

  invite = () => {
    var isValidEmail = !emailInvalid(this.state.ReferralInvite.EmailAddr);
    if (!isValidEmail) {
      this.setState({ReferralInvite: {...this.state.ReferralInvite, dirty: true}});
    } else {
      console.log('inviteLoading true')
      this.setState({ReferralInvite: {...this.state.ReferralInvite, dirty: true},inviteLoading: true});
      shareReferralCode(getToken(),this.state.ReferralInvite.EmailAddr)
      .then(({error,response}) => {
        if (error.status<400) {
          this.setState({inviteText: 'Invited!', inviteLoading: false, ReferralInvite: {EmailAddr: '', dirty: false}});

          setTimeout(() => {
            this.setState({inviteText: 'Invite'})
          },5000)
        }


      })
    }
  }

  render() {
    const inviteDisabled = emailInvalid(this.state.ReferralInvite.EmailAddr);
    const isFree = this.state.ReferralDetails ? this.state.ReferralDetails.IsFree : false;
    console.log('inviteLoading',this.state.inviteLoading)
    return (
        <>
          <div className={styles.plan_section}>
            <div className="container-fluid">
                <div className={["row", "plan-grey-background"].join(" ")}>
                  <PlanSideBar page={"Referral"} />
                    <div className="col-lg-10 col-md-12 right_section">
                        <div className={["mpr_plan_section", "p-lg-5", "p-md-3" ].join( ' ' )}>
                            <div className={["mpr_c_plan_title", "mt-5", "pb-5"].join(" ")}>
                              <h2>Referral Panel</h2>
                            </div>
                            {
                              isFree
                              ?
                              <div className={["card", "mpr-card-shadow", "p-4", "mb-md-5", "mb-3"].join(" ")}>
                                <div className="row">
                                  <div className={["col-md-10"].join(" ")}>
                                    <span>Subscribe to a paid plan to join the MProfit Referral Program</span>
                                  </div>
                                </div>
                              </div>
                              :
                              <>
                              <div className={["card", "mpr-card-shadow", "p-4", "mb-md-5", "mb-3"].join(" ")}>
                                <div className="row">
                                  <FormField fieldName="ReferralLink" label="My Referral Link" sectionName={'ReferralDetails'} sectionValue={this.state} handleInputChange={()=>{}} perLine={2} disabled={true} showCopy={true} disabledBlack={true}/>
                                  <FormField fieldName="ReferralPoints" label="Referral Points Earned" sectionName={'ReferralDetails'} sectionValue={this.state} handleInputChange={()=>{}} perLine={2} disabled={true} inputType={'number'} disabledBlack={true}/>
                                </div>
                                <div className="row">
                                  <div className={["col-md-10", styles.policyBlock].join(" ")}>
                                    <span>For every new MProfit customer referred by you, they will get a <strong>10% discount</strong> and you will earn <strong>INR 1500</strong> as Referral Points credit. Use this credit as a discount when you renew or upgrade your plan. Invite people to try MProfit now!</span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className={[styles.inviteBlock].join(" ")}>
                                    <div className={["form-inline", "invite-block"].join(" ")}>
                                      <FormField fieldName="EmailAddr" label="Enter email address" sectionName={'ReferralInvite'} sectionValue={this.state} handleInputChange={this.onChange} perLine={2} disabled={false} inputType={'email'} customValidation={'email'} dirty={this.state.ReferralInvite.dirty} onEnter={this.invite}/>
                                      <ProceedButton onClick={this.invite} btnClasses={["btn", "btn-primary", "btn-sm", "rounded","mt-3",styles.inviteBtn].join(" ")} buttonText={this.state.inviteText} borderRadius={4} disabled={inviteDisabled} loading={this.state.inviteLoading}/>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className={["col-md-6", styles.viewPolicyBlock].join(" ")}>
                                    <span>View MProfit's <Link to="/refer">Referral Policy</Link></span>
                                  </div>
                                </div>
                              </div>

                              <ProfileSectionHeading text={'Referral History'} showTooltip={true} tooltipText={'Referral history is available from June 2019'} tooltipName={"referral-history-tooltip"}/>
                              {
                                this.state.ReferralDetails && this.state.ReferralDetails.ReferralHistory.length>0
                                ?
                                <>
                                  {
                                    this.state.ReferralDetails.ReferralHistory.map((referral) => 
                                    (
                                      <ReferralItem key={referral.ReferralId} referral={referral}/>
                                    ))
                                  }
                
                                  {/* <div className={["mt-5", "pb-5", "text-center"].join(" ")}>
                                    <button className={["btn", "btn-primary", "rounded"].join(" ")}>View all</button>
                                  </div> */}
                                </>
                                :
                                <div className={["card", "mpr-card-shadow", "p-md-4", "pl-4", "pr-4", "pt-3", "pb-3", "mb-4"].join(" ")}>
                                  <div className="row">
                                    <div className={["col-md-12", "col-sm-12"].join(" ")}>
                                      <h5 className={styles.noReferral}>{'You have not referred anyone yet. Start earning credit towards your MProfit subscription by referring new users today!'}</h5>
                                    </div>
                                  </div>
                                </div>
                              }
                              </>
                            }
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </>
      )
    }
}

const ReferralItem = ({referral}) => {
  return (
    <div className={["card", "mpr-card-shadow", "p-md-4", "pl-4", "pr-4", "pt-3", "pb-3", "mb-4"].join(" ")}>
      <div className="row">
        <div className={["col-md-3", "col-7", "mb-md-3", "mb-2", "mb-md-0", "order-1"].join(" ")}>
          <label className={["mb-2"].join(" ")}>Date</label>
          <h5>{dateFormat(referral.ReferralDt,'dd/mm/yy')}</h5>
        </div>
        <div className={["col-md-3", "col-5", "mb-md-3", "mb-2", "mb-md-0", "order-2"].join(" ")}>
          <label className="mb-2">Points Earned</label> 
          <h5>{referral.ReferralPointsEarned}</h5>
        </div>
        <div className={["col-md-6", "col-7", "mb-md-3", "mb-2", "mb-md-0", "order-3"].join(" ")}>
          <label className="mb-2">Customer Referred</label> 
          <h5>{referral.ReferralToName}</h5>
        </div>
        {/* <div className={["col-md-3", "col-5", "mb-md-3", "mb-2", "mb-md-0", "order-4", "order-md-3"].join(" ")}>
          <label className={["mb-2", "d-none", "d-md-block"].join(" ")}>Subscribed Plan</label> 
          <h5 className={styles.investor_label}>{referral.ProductName}</h5>
        </div> */}
      </div>
    </div>
  )
}

export default Referral