import React from "react"
import styles from "./styles.module.css"
import { Link, navigate } from "gatsby"
import { logout, getUser } from "../../services/auth";

class PlanSideBar extends React.Component {
    constructor(props) {
        super(props);
        const user = getUser();
        this.state = {
            user
        };
    }

    navigateToLogin = () => {
        navigate('/login');
    }


    render() {
        const page = this.props.page;

        const cloudLink = "https://cloud.mprofit.in" + (this.state.user ? "/login?email="+encodeURIComponent(this.state.user.email) : "");

        return (
            <>
                <div className={["col-lg-2","col-md-3", "p-0", "pt-5", styles.mpr_sidebar, "d-none", "d-lg-block"].join(" ")}>
                    <div className={["mt-4", styles.sticySidebar].join(" ")}>
                        <div className={styles.listGroup} id="list-tab" role="tablist">
                            <Link className={[styles.listGroupItem, "list-group-item", "list-group-item-action", page==="Plan" ? styles.active : ""].join(" ")} to="/account/plan">My Plan</Link>
                            <Link className={[styles.listGroupItem, "list-group-item", "list-group-item-action", page==="Users" ? styles.active : ""].join(" ")} to="/account/users">My Users</Link>
                            {
                                !this.props.isTemp &&
                                <>
                                <Link className={[styles.listGroupItem, "list-group-item", "list-group-item-action", page==="Referral" ? styles.active : ""].join(" ")} to="/account/referral">Referral Panel</Link>
                                <Link className={[styles.listGroupItem, "list-group-item", "list-group-item-action", page==="Profile" ? styles.active : ""].join(" ")} to="/account/profile">My Profile</Link>
                                <Link className={[styles.listGroupItem, "list-group-item", "list-group-item-action", page==="OrderHistory" ? styles.active : ""].join(" ")} to="/account/order-history">Order History</Link>
                                </>
                            }
                            <a className={[styles.listGroupItem, "list-group-item", "list-group-item-action"].join(" ")} href={cloudLink} target="_blank" rel="noopener noreferrer" >MProfit Cloud <span><i className={["fa", "fa-external-link",styles.icon].join(" ")} aria-hidden="true"></i></span></a>
                            <div className={[styles.listGroupItem, "list-group-item", "list-group-item-action"].join(" ")} onClick={()=>logout(this.navigateToLogin)}>Sign out</div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}


export default PlanSideBar

