
import React from "react"
import styles from "./styles.module.css";
import Tooltip from "../Tooltip";
import ProceedButton from "../../components/proceedButton";

const ProfileSectionHeading = ({text, icon, onClick, noMarginTop=false,showTooltip=false,tooltipText=null,tooltipHeader=null, tooltipName, useTextIcon=false, iconText='Edit'}) => {
    return (
      <div className={["mpr_profile_title", noMarginTop ? "" : "mt-5", "pb-4"].join(" ")}>
        <div className={["d-flex flex-row", "align-items-center justify-content-between"].join(" ")}>
          <div className="flex-grow">
              <h2 className={["d-inline-block",styles.sectionHeader].join(" ")}>
                {text}
                {
                  showTooltip && tooltipName &&
                  <>
                    <span className="qcircle" id={tooltipName} data-toggle="tooltip" data-placement="right">?</span>
                    <Tooltip text={tooltipText} header={tooltipHeader} target={tooltipName}/>
                  </>
                }
              </h2>
          </div>
          {
            (icon || iconText) && onClick &&
            <div className={["flex-none ml-2", "text-right"].join(" ")}>
              {
                useTextIcon ?

                <ProceedButton buttonText={iconText} borderRadius={4} btnClasses={["btn", "btn-primary", "btn-sm", "rounded",styles.textIcon].join(" ")} onClick={onClick}/>
                :
                <button className={["btn", "p-0","btn_edit"].join(" ")} onClick={onClick}><img src={icon}   alt=""  /></button>
              }
            </div>
          }
        </div>
      </div>
    )
}

export default ProfileSectionHeading